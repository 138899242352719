import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useDeleteUploadFiles, useMoveToTrash } from 'features/sump'
import { useUploadedFilesCount } from 'features/uploaded-file/api/query'
import { useTabAction, useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import UploadedFileTable from 'features/uploaded-file/ui/table/UploadedFileTable'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import i18next from 'i18next'
import { viewerPageSlice } from 'pages/viewer'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { TableTabs, TTableTabsProps } from 'shared/ui/tabs/TableTabs'
import styled from 'styled-components/macro'
import { EUploadedFileTab } from 'types/IUploadedFile'

import FileAttachAction from './actions/FileAttachAction'
import UploadedFileActions from './actions/UploadedFileActions'
import { useUploadedFileContext } from './table/UploadedFileContext'
import { useUploadedFileTabContext } from './UploadedFileTabContext'

const UploadedFileTabsWrapper = styled(TableTabs)<TTableTabsProps & { isFiltersPicked: boolean }>`
  .ant-tabs-nav {
    background-color: var(--color-bg-1);
    padding-right: ${({ isFiltersPicked }) => (isFiltersPicked ? '16px' : '118px')};
  }

  .ant-tabs-nav-wrap {
    background-color: var(--color-bg-1);
    flex: none !important;
    padding-right: 10px;
  }

  .ant-tabs-extra-content {
    flex: auto;
  }
`

const UploadedFileTabs = () => {
  const { t } = useTranslation()
  const { currentTab } = useUploadedFileQueryParams()
  const { isFiltersPicked } = useCaseManagementContext()
  const dispatch = useDispatch()
  const wsId = useCurrentWorkspaceId()
  const { data: counts } = useUploadedFilesCount()
  const { isLisMode } = useSettingsAndUserRoles()
  const { mutate: deleteFiles } = useDeleteUploadFiles()
  const { mutate: moveToTrash } = useMoveToTrash()
  const { resetSelectedRows, setSelectedFile, setSelectedFiles, setSelectedRowIndex } = useUploadedFileTabContext()
  const dupBarcode = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)
  const { searchString } = useUploadedFileContext()
  const tabActionInfo = useTabAction()

  const uploadedFileTabs = useMemo(
    () =>
      [
        {
          label: t('Ожидает'),
          tabType: EUploadedFileTab.AWAITING_CASE,
          visible: isLisMode,
        },
        {
          label: t('Ошибки'),
          tabType: EUploadedFileTab.NOT_ATTACHED_TO_CASE,
          visible: true,
        },
        {
          label: t('Разобрано'),
          tabType: EUploadedFileTab.ATTACHED_TO_CASE,
          visible: true,
        },
        {
          label: t('Корзина'),
          tabType: EUploadedFileTab.DELETED,
          visible: true,
        },
      ]
        .filter((tab) => tab.visible)
        .map(({ tabType, ...rest }, index) => ({
          ...rest,
          count: counts?.[tabType]?.total,
          id: (index + 1).toString(),
          tabType,
        })),
    [counts, i18next.language],
  )

  const activeTabType = currentTab || uploadedFileTabs[0].tabType
  const filters = useMemo(
    () => ({
      query: dupBarcode,
      uploadedFileAttachmentStates: 'DUPLICATE',
      uploadedFileTab: 'NOT_ATTACHED_TO_CASE',
    }),
    [dupBarcode],
  )

  const onTabClick = (key: string) => {
    if (
      [EUploadedFileTab.SEARCH, EUploadedFileTab.DUPLICATE, EUploadedFileTab.SELECTED].includes(key as EUploadedFileTab)
    )
      return
    dispatch(push(`/workspace/${wsId}/warehouse?tab=${key}`))
  }

  const onDelete = useCallback(
    (uploadedFileIds: number[]) => {
      setSelectedFile(undefined)
      resetSelectedRows()

      // Определение действия, которое нужно выполнить на основе активной вкладки
      const isDeleteAction = [EUploadedFileTab.ATTACHED_TO_CASE, EUploadedFileTab.DELETED].includes(activeTabType)

      // В зависимости от вкладки, выполняем либо удаление, либо перемещение в корзину
      isDeleteAction ? deleteFiles({ uploadedFileIds }) : moveToTrash({ uploadedFileIds })
    },
    [activeTabType],
  )

  useEffect(() => {
    setSelectedFiles([])
    setSelectedFile(undefined)
    setSelectedRowIndex(null)
  }, [activeTabType])

  useEffect(() => {
    if (activeTabType === currentTab) return
    dispatch(push(`/workspace/${wsId}/warehouse?tab=${activeTabType}`))
  }, [])

  useEffect(() => {
    const { resetSumpSearchQuery, setSumpFilters } = viewerPageSlice.actions

    dispatch(
      dupBarcode.length
        ? setSumpFilters({
            filters: {
              ...filters,
              query: dupBarcode,
            },
            key: currentTab,
          })
        : resetSumpSearchQuery({ key: currentTab }),
    )
  }, [dupBarcode])

  return (
    <UploadedFileTabsWrapper
      tabsItems={tabActionInfo?.actionTab ?? uploadedFileTabs}
      onTabClick={onTabClick}
      activeTabType={tabActionInfo?.tabType ?? activeTabType}
      tabBarExtraContent={<UploadedFileActions>{tabActionInfo?.extraContent}</UploadedFileActions>}
      isFiltersPicked={isFiltersPicked}
    >
      {(activeTabType !== EUploadedFileTab.DELETED || !!searchString?.length) && (
        <FileAttachAction onDelete={onDelete} />
      )}
      <UploadedFileTable />
    </UploadedFileTabsWrapper>
  )
}

export default UploadedFileTabs
